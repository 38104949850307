import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
    root: {
        padding: '30px 0 25px 0',
        '& .MuiSelect-root': {
            fontSize: 14,
            padding: '5px 32px 11px 14px !important',
        },
        '& .MuiInputLabel-root': {
            textTransform: 'capitalize',
            transform: 'translate(0px, 0px) !important',
            color: '#000000',
            fontSize: '15px',
            fontWeight: '500',
            fontFamily: 'Avenir-Roman',
        },
        '& .Mui-error': {
            color: '#ff1744 !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            '& legend': {
                display: 'none !important',
            },
        },
        '& .MuiSelect-icon': {
            transform: 'translateY(-50%) !important',
            top: '42% !important',
        },
        '& .MuiFormHelperText-root': {
            margin: '0 !important',
        },
    },
    error: {
        fontSize: '10px !important',
    },
}));
