import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import classNames from 'classnames';
import Typography from '@common_typography';
import useStyles from './style';

const CustomTextField = ({
    placeholder = '',
    disabled = false,
    onChange = () => { },
    value = '',
    className = '',
    label = '',
    fullWidth = true,
    shrink = true,
    error = false,
    errorMessage = '',
    variant = 'outlined',
    isMultiline,
    footer,
    required,
    ...other
}) => {
    const styles = useStyles();
    const customClass = classNames(styles.container, className);
    return (
        <FormControl
            disabled={disabled}
            fullWidth={fullWidth}
            error={error}
            variant={variant}
            className={customClass}
        >
            <InputLabel
                shrink={shrink}
                htmlFor={label}
                required={required}
                className={classNames(styles.label, 'avenir-medium')}
            >
                {label}
            </InputLabel>
            {
                isMultiline
                    ? (
                        <Input
                            id={label}
                            multiline
                            rows={5}
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                            className={styles.inputMultiline}
                            {...other}
                        />
                    ) : (
                        <Input
                            id={label}
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                            className={styles.input}
                            {...other}
                        />
                    )
            }
            {React.isValidElement(footer) ? (
                footer
            ) : (
                <Typography variant="p" color={error ? 'red' : 'default'}>
                    {errorMessage}
                </Typography>
            )}
        </FormControl>
    );
};

export default CustomTextField;
