import { makeStyles } from '@material-ui/core/styles';
import { CreatePadding } from '@theme_mixins';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
        ...CreatePadding(20, 0, 15, 0),
    },
    label: {
        textTransform: 'capitalize',
        transform: 'translate(0px, 0px) !important',
        color: '#000000',
        fontSize: '15px',
        fontWeight: '500',
        fontFamily: 'Avenir-Roman',
        "& .MuiInputLabel-asterisk": {
            color: 'red'
        }
    },
    input: {
        marginTop: '4px',
        height: 40,
        fontSize: 14, 
    },
    inputMultiline: {
        marginTop: '6px',
        height: 'auto',
        fontSize: 14,
    },
}));

export default useStyles;
